import './import/dxcard';
import './import/header';
import './import/pricing';
import './import/ui';
import './import/scroller';
import './import/small-business';
import './import/swiper';
import './import/timer';
import './import/video';

// import objectFitImages from 'object-fit-images';




// objectFitImages();

