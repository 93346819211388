import skrollr from '../lib/skrollr.js';




// $(document).ready(function() {
//   if($(window).width() > 767) {
//     var s = skrollr.init({
//       smoothScrolling: true,
//     });
//   }
// });
